<template>
    <div class="base-width">
        <el-row :gutter="15">
            <el-col :span="4">
                <div class="user-info text-center user-card">
                    <div class="user-face">
                        <img :src="$store.state.userData.face" alt="">
                    </div>
                    <div class="username bbs-second-color">
                        {{ ($store.state.userData.nickname || $store.state.userData.username) || '请登录' }}
                    </div>
                </div>
                <div class="creating-center user-card">
                    <div class="title">创作中心</div>
                    <div class="body">
                        <div @click="clickItem(item)" v-for="item in type" :key="item.name" class="item"
                             :class="{active:item.path===active}">
                            <div class="title-icon" :class="['title-icon-'+item.name]">
                                <i :class="[item.icon]"></i>
                            </div>
                            <div class="text">
                                {{ item.title }}
                            </div>
                            <!--                            <div class="sum">-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div v-if="$store.state.userData.group_id==1" class="creating-center user-card">
                    <div class="title">内容中心</div>
                    <div class="body">
                        <div @click="clickItem(item)" v-for="item in verify" :key="item.name" class="item"
                             :class="{active:item.path===active}">
                            <div class="title-icon" :class="['title-icon-'+item.name]">
                                <i :class="[item.icon]"></i>
                            </div>
                            <div class="text">
                                {{ item.title }}
                            </div>
                            <div class="sum"
                                 v-if="$store.state.notice[item.name]&&$store.state.notice[item.name].length">
                                {{ $store.state.notice[item.name] && $store.state.notice[item.name].length }}
                                <!--                                <el-badge slot="reference" :value="2">-->

                                <!--                                </el-badge>-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rights-center user-card">
                    <div class="title">权益中心</div>
                    <div class="body">
                        <div @click="clickItem(item)" v-for="item in rights" :key="item.name" class="item"
                             :class="{active:item.path===active}">
                            <div class="title-icon">
                                <i :class="[item.icon]"></i>
                            </div>
                            <div class="text">
                                {{ item.title }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="setting-center user-card">
                    <div class="title">个人中心</div>
                    <div class="body">
                        <a @click="clickItem(item)" v-for="item in profile" :key="item.name"
                           :class="{active:item.path===active}" class="item">
                            <div class="title-icon">
                                <i :class="[item.icon]"></i>
                            </div>
                            <div class="text">
                                {{ item.title }}
                            </div>
                        </a>
                    </div>
                </div>
            </el-col>
            <el-col :span="20">
                <router-view></router-view>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import storage from "@/utils/storage";

export default {
    components: {},
    data() {
        return {
            checkTimer: '',
            active: '',
            type: [
                {title: '我发布的', icon: 'el-icon-edit-outline', sum: 0, name: 'create', path: '/user/answer/my'},
                {
                    title: '我回答的',
                    icon: 'el-icon-chat-dot-square',
                    sum: 0,
                    name: 'answer',
                    path: '/user/answer/answer'
                },
                {title: '草稿箱', icon: 'el-icon-star-off', sum: 0, name: 'like', path: '/user/answer/draft'},
            ],
            verify: [
                {title: '全部', icon: 'el-icon-edit-outline', name: 'all', path: '/user/answer/all'},
                {
                    title: '待审核问答',
                    icon: 'el-icon-edit-outline',
                    sum: 0,
                    name: 'answer',
                    path: '/user/verify/answer'
                },
                {
                    title: '待审核回复',
                    icon: 'el-icon-chat-dot-square',
                    sum: 0,
                    name: 'reply',
                    path: '/user/verify/reply'
                },
                {title: '待审核评论', icon: 'el-icon-star-off', sum: 0, name: 'comment', path: '/user/verify/comment'},
                {title: '搜索统计', icon: 'el-icon-search', sum: 0, name: 'sum', path: '/user/answer/sum'},
                {title: '用户列表', icon: 'el-icon-user', sum: 0, name: 'user', path: '/user/list'},
            ],
            rights: [
                {title: '我的积分', icon: 'el-icon-coin', sum: 0, name: 'coin', path: '/user/profile/coin'},
            ],
            profile: [
                {title: '账号设置', icon: 'el-icon-setting', sum: 0, name: 'setting', path: '/user/profile/setting'},
                // {title: '退出登录', icon: 'el-icon-warning-outline', sum: 0, name: 'out'},
            ],
        }
    },
    mounted() {
        this.active = this.$route.path;
        this.checkTimer = setInterval(() => {
            this.checkDoneNotice();
        }, 200);
        // window.abcd = this.checkDoneNotice;
        if (this.$tools.checkLogin()) {
            return;
        }
    },
    watch: {
        '$route': function (to) {
            this.active = to.path;
        }
    },
    methods: {
        checkDoneNotice() {
            let doneData = storage.elseStorage();
            if (!doneData) return;
            let oldData = JSON.parse(JSON.stringify(this.$store.state.notice));
            let change = false;
            [{key: 'notice', id: 'notice_id'}, {key: 'answer', id: 'answer_id'}, {
                key: 'comment',
                id: 'comment_id'
            }, {key: 'reply', id: 'reply_id'}].map(v => {
                if (!Array.isArray(oldData[v.key]) || !doneData[v.key]) return;
                //首先获取有哪些ID是处理了的
                let del = [];
                for (let k in doneData[v.key]) {
                    let index = oldData[v.key].findIndex(vv => {
                        return vv[v.id] == k;
                    });
                    if (index > -1) {
                        change = true;
                        oldData[v.key].splice(index, 1);
                    }
                    //由于用户可能打开了多个界面在处理，所以这里我设置超过一个小时才会删除审核的ID
                    if (!doneData[v.key][k] || (new Date().getTime() - doneData[v.key][k].time) > 3600 * 1000) {
                        del.push(k);
                    }
                }
                del.map(vv => {
                    delete doneData[v.key][vv];
                });
            });
            storage.elseStorage(doneData);
            if (change) {
                this.$store.commit('updateNotice', oldData);
            }
        },
        clickItem(item) {
            this.$router.push({
                path: item.path,
            });
        }
    },
    beforeDestroy() {
        clearInterval(this.checkTimer);
    }
}
</script>
<style scoped lang="less">
.base-width {
    padding: 20px;
}

.user-info {
    background-color: #fff;
    padding: 40px;

    .user-face {
        img {
            width: 50px;
            height: 50px;
            display: inline-block;
            padding: 2px;
            border: 1px solid #ccc;
            border-radius: 100%;
        }
    }

    .username {
        padding-top: 10px;
    }
}

.user-card {
    background-color: #fff;
    margin-top: 1.5rem;
    padding: 15px;

    .title {
        font-weight: 600;
    }

    .body {
        padding-top: 15px;
        padding-left: 15px;

        .item {
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }

            &.active {
                .text {
                    color: #1a92f1;
                    text-decoration: underline;
                }
            }

            color: #666;
            display: flex;
            padding: 8px 0;

            .title-icon {
                color: #2A7EFF;
                padding-right: 5px;
            }

            .sum {
                padding: 1px 2px;
                color: #fff;
                text-decoration: none;
                border-radius: 10px;
                font-size: 12px;
                transform: translate(0, -5px);
                background-color: #F56C6C;
            }
        }
    }
}

.creating-center {

}
</style>
